import React from 'react'
import Layout from '../../Components/utils/Layout'
import Process from './Process'

const ProcessPage = () => {
  return (
    <>
    <div className='w-[100%]'>

      <Layout >

      <Process />
      </Layout>
    </div>
    </>
  )
}

export default ProcessPage
