import React from 'react'
import Layout from "../../Components/utils/Layout"
import AboutUsSection from './AboutUsSection'
import VisionSection from './VisionSection'
import MissionSection from './MissionSection'
import GoalSection from './GoalSection'
import Valuesection from './Valuesection'
import Inspiration from '../Process/Inspiration'

const AboutUsPage = () => {
  return (
    <>
      <div className="relative w-[100%]">
        <Layout >

        <Inspiration />
        <AboutUsSection />

        <VisionSection />

        <MissionSection />

        <GoalSection />

        <Valuesection />
        </Layout>

      </div>
    </>
  )
}

export default AboutUsPage
