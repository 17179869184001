import React from 'react'
import ServiceBanner from "../../assets/images/Services/Banner (2).svg"
import ServiceBanner2 from "../../assets/images/Services/Banner (4) (1).svg"
import Slider from 'react-slick';

const ServiceBannerSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    // cssEase: "linear",
    arrows : false,
  };

  return (
    <>
      <div className='serviceSlick  w-[100%] z-[40] mt-[70px] max_sm:mt-[60px]' style={{textAlign: '-webkit-center'}}>

     
      <Slider {...settings}>
        <div className='w-[80%]'>
          <img className='w-full' src={ServiceBanner} alt='' />
        </div>
        <div className='w-[80%]'>
          <img className='w-full' src={ServiceBanner2} alt='' />
        </div>
      </Slider>
      </div>
    </>

  )
}

export default ServiceBannerSection
