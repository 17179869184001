import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Navbar from '../Navbar';
import MobileMenu from '../MobileMenu';
import Footer from '../Footer';

const Layout = ({ children }) => {
  const nodeRef = useRef(null);
   const outsideRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);

  // useOnClickOutside(outsideRef, () => setIsMobileMenuOpen(false));
 
  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', changeBackground);
      };
    }
  });
  return (
    <>
      <div className="relative w-full">
        <div className={`fixed top-0 z-50 w-full`}>
          <div
            className={` relative m-auto w-full ${
              navbarChangeColor
                ? 'bg-white shadow-sm'
                : 'bg-LightYellow'
            }`}
          >
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              nodeRef={nodeRef}
              timeout={500}
              classNames="menu"
              type={'out-in' | 'in-out'}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
              />
            </CSSTransition>
          </div>
        </div>
        <div>{children}</div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
