import React from 'react'
import Logo from "../assets/images/Navbar/Logo (1).svg";
import Email from "../assets/images/Footer/Email.png";
import Phone from "../assets/images/Footer/Phone.png";
import Wp from "../assets/images/Footer/Whatsapp icon.svg";
import Insta from "../assets/images/Footer/Insta icon.svg";
import FB from "../assets/images/Footer/facebook icon (1).png";
import YT from "../assets/images/Footer/Yt icon.svg";
import FooterImg from "../assets/images/Footer/Mask group (7).svg"
import LocationImg from "../assets/images/Footer/Location (2).svg"
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='w-[100%] bg-LightYellow '>
        <div className='z-[40] relative flex flex-col m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] pt-[90px] max_md:pt-[70px] max_sm:pt-[40px]'>
          <div className='flex justify-center max_768:flex-col'>

            <div className='w-[34%] max_768:w-[100%]'>
              <div>
                <img src={Logo} alt='Logo' />
              </div>
              <div className='NormalText16 mt-7'>Aghnya Organiko Health Care, Third party manufacturing/private labelling available</div>
            </div>
            <div className='w-[66%] max_768:hidden flex gap-[8%] justify-end max_md:gap-[11%] max_sm:gap-[8%] max_350:flex-wrap max_350:gap-[20%]'>
              <div className='NormalText16 flex flex-col gap-[10px]'>
                <div className='cursor-pointer' onClick={()=>navigate("/")}>Home</div>
                <div className='cursor-pointer' onClick={()=>navigate("/category")}>Category</div>
                <div className='cursor-pointer' onClick={()=>navigate("/process")}>Process</div>
                <div className='hidden max_md:block cursor-pointer' onClick={()=>navigate("/about-us")}>About us</div>
                <div className='hidden max_md:block cursor-pointer' onClick={()=>navigate("/services")}>Services</div>
                <div className='hidden max_md:block cursor-pointer' onClick={()=>navigate("/contact-us")}>Contact us</div>

              </div>
              <div className='NormalText16 flex flex-col gap-[10px] max_md:hidden'>
                <div className='cursor-pointer' onClick={()=>navigate("/about-us")}>About us</div>
                <div className='cursor-pointer' onClick={()=>navigate("/services")}>Services</div>
                <div className='cursor-pointer' onClick={()=>navigate("/contact-us")}>Contact us</div>
              </div>

              <div className='flex flex-col gap-[10px]'>
                <div className='flex gap-2 items-center NormalText16'>
                  <div>
                    <img src={Phone} alt='Call' />
                  </div>
                  <a href="tel:+91 9510501099">
                    <div>+91 9510501099</div>
                  </a>
                </div>
                <div className='flex gap-2 items-center NormalText16'>
                  <div>
                    <img src={Email} alt='Email' />
                  </div>
                  <a href="mailto:INFO@Aghanyaorganiko.com"
                    onClick={() => "window.location=another.html"}
                  >
                    <div>INFO@Aghanyaorganiko.com</div>
                  </a>
                </div>
                
                <div className='flex gap-5 mt-2'>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={Wp} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={Insta} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={FB} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={YT} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                </div>
              </div>
              <div>

              </div>
            </div>

            {/* Tablate View */}
            <div className='hidden max_768:block'>
              <div className='flex NormalText16 justify-between mt-5'>
                <div className='flex flex-col gap-5'>
                  <div className='cursor-pointer' onClick={()=>navigate("/")}>Home</div>
                  <div className='cursor-pointer' onClick={()=>navigate("/category")}>Category</div>
                </div>
                <div className='flex flex-col gap-5'>
                  <div className='cursor-pointer' onClick={()=>navigate("/process")}>Process</div>
                  <div className='cursor-pointer' onClick={()=>navigate("/about-us")}>About us</div>
                </div>
                <div className='flex flex-col gap-5'>
                  <div className='cursor-pointer' onClick={()=>navigate("/services")}>Services</div>
                  <div className='cursor-pointer' onClick={()=>navigate("/contact-us")}>Contact us</div></div>
              </div>
              <div className='mt-5 flex flex-col gap-[20px]'>
                <div className='flex gap-2 items-center NormalText16'>
                  <div>
                    <img src={Phone} alt='Call' />
                  </div>
                  <a href="tel:+91 9510501099">
                    <div>+91 9510501099</div>
                  </a>
                </div>
                <div className='flex gap-2 items-center NormalText16'>
                  <div>
                    <img src={Email} alt='Email' />
                  </div>
                  <a href="mailto:INFO@Aghanyaorganiko.com"
                    onClick={() => "window.location=another.html"}
                  >
                    <div>INFO@Aghanyaorganiko.com</div>
                  </a>
                </div>
                
                <div className='flex gap-5 mt-2'>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={Wp} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={Insta} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={FB} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                  {/* <a
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                  <div>

                    <img src={YT} alt='Whatsapp' className='cursor-pointer' />
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
          <div className='mt-[70px] max_sm:mt-[42px] py-[6px] text-center NormalText16 bg-PrimaryBrown text-white'>Copyright © 2023 Aghnya. All rights reserved</div>

        {/* <div className='w-[100%] mt-[-165px] max_xl1160:mt-[-130px] max_md:mt-[-100px] max_768:mt-[-60px] max_sm:mt-[-20px]'>
          <img src={FooterImg} alt='' className='w-[100%] object-contain' />
        </div> */}
      </div>
    </>
  )
}

export default Footer
