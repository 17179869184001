import React from 'react'

const CommonProduct = ({ productDetails, image,title }) => {
  return (
    <>
      <div className="z-[40] mt-[70px] max_sm:mt-[60px] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
        <div className='max_768:w-[100%] flex gap-7 max_768:flex-col max_md:gap-4'>
          <div className='w-[38%] max_768:w-[100%] max_md:F-JC-AI-CENTER'>
            <img src={image} alt='' />
          </div>
          <div className='w-[1px] h-[400px] bg-PrimaryBrown max_md:hidden'></div>
          <div className='w-[62%] max_768:w-[100%]'>
            {productDetails.map((items, index1) => {
              return (<React.Fragment key={index1}>
                <p className='commonHeading uppercase 
            text-PrimaryBrown'>{items.name}</p>
                {items.title.map((inneritems, index2) => {
                  return (<React.Fragment key={index2}>
                    <p className='NormalText16 mt-[10px]'>{inneritems}</p>
                  </React.Fragment>)
                })}
                <p className='NormalText20 mt-[40px] max_md:mt-5'>{items.head1}</p>
                {items.head1Text.map((headText1,index3)=>{
                  return (
                    <React.Fragment key={index3}>
                      <p className='NormalText16 mt-[10px]'>{headText1}</p>
                    </React.Fragment>
                  )
                })}
                <p className='NormalText20 mt-[40px] max_md:mt-5'>{items.head2}</p>
                {items.head2Text.map((head2Text, index3) => {
                  return (
                    <React.Fragment key={index3}>
                      <p className='NormalText16 mt-[10px]'>{head2Text}</p>
                    </React.Fragment>)
                })}
                {items.head3 && (<>
                
                <p className='NormalText20 mt-[40px] max_md:mt-5'>{items.head3}</p>
                {items.head3Text.map((head3Text, index4) => {
                  return (
                    <React.Fragment key={index4}>
                      <p className='NormalText16 mt-[10px]'>{head3Text}</p>
                    </React.Fragment>)
                })}
                </>)}
              </React.Fragment>)
            })}



          </div>
        </div>
      </div>
    </>
  )
}

export default CommonProduct
