import React from 'react'
import Layout from '../../Components/utils/Layout'
import CategoryBannerSection from './CategoryBannerSection'
import OurProductRangeComponent from './OurProductRangeComponent'

const CategoryPage = () => {
  return (
    <>
    <div className='w-[100%]'>
      <Layout >

        
      <CategoryBannerSection />

      <OurProductRangeComponent />
      </Layout>
    </div>
    </>
  )
}

export default CategoryPage
