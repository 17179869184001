import React from 'react'
import ContactUs from './ContactUs'
import Layout from '../../Components/utils/Layout'

const ContactUsPage = () => {
  return (
    <>
    <Layout >
      <div className='mt-[70px]'>

      <ContactUs />
      </div>
    </Layout>
    </>
  )
}

export default ContactUsPage
