import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import CommonProduct from '../../Components/CommonProduct'
import BabyDiaperImg from "../../assets/images/Product/Diaper.svg"

const BabyDiaperBalm = () => {
  const [productDetails] = useState([
    {
      image:BabyDiaperImg,
      name: 'Baby Diaper Balm',
      title: ["Soothing comfort for baby’s balm", 'Quick soothing solution'],
      head1: 'Ingredient',
      head1Text: ['This Product is only made up of 5 ingredients which are sunflower oil, beeswax, coconut oil, shea butter and calendula.'],
      head2: 'Other details',
      head2Text: ['Project to use on dry skin areas on the face.', 'Natural ingredients work great at moisturizing', 'Rich in sunflower and coconut oil for smoothening', 'Has no fragrance and synthetic ingredients','Great for travel and quick applications.']
    }
  ])
  return (
    <>
      <Layout >

      <CommonProduct productDetails={productDetails} image={BabyDiaperImg} title="Baby Diaper Balm" />
      </Layout>
    </>
  )
}

export default BabyDiaperBalm
