import React from 'react'
import OwnerImg from "../../assets/images/Process/1 1.svg"
import LeftVector from "../../assets/images/Process/Vector (8).png"
import RightVector from "../../assets/images/Process/Vector (7).png"

const Inspiration = () => {
  return (
    <>
      <div className="z-[40] mt-[70px] relative flex items-center justify-center m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[16px] max_sm:mt-[60px]">
        <div className='flex flex-col items-center'>
          <p className='commonHeading pt-[30px] pb-[10px] max_esm:text-[22px] text-center'>INSPIRATION THAT CREAT</p>
          <p className='text42'>Aghnya Organiko</p>
          <div className='pt-[30px] flex justify-center'>
            <div className='relative w-max rounded-[500px] border-PrimaryBrown border-[1px] max_sm:w-[80%] bg-white'>
              <img src={OwnerImg} alt='inspiration_img' className='p-[10px]' />

              <div className='absolute left-[-95px] bottom-0 z-[-1] max_sm:w-[30%] max_sm:left-[-35px]'>
                <img src={LeftVector} alt='' />
              </div>
              <div className='absolute right-[-95px] bottom-0 z-[-1] max_sm:w-[30%] max_sm:right-[-35px]'>
                <img src={RightVector} alt='' />
              </div>
            </div>
          </div>

          <div className='flex flex-col NormalText18 mt-[50px]'>
            <p>Beauty products loaded with harmful chemicals and toxins leading to chronic health issues,
              pained Mallika Tiwari, day in and day out.</p>
            <p>Concerned with her own skin problems and troubled to see how everyone around was consuming
              chemicals in the name of soaps, shampoos, lotions and whatnot, she was keen to find solution.
              A passion for Healthy Skin and Hair and Overall well being inspired Mallika to start creating her own
              skin and hair care products.</p>
            <p>
              Cows are revered since the Vedic Era, because of the tremendous value they can bring to Human Life.
              Concerned to see cows being abandoned and killed, Mallika was determined to bring back the glory
              of the blessing that Cow is and encourage the use of the valuable gifts that Cow gives to us.</p>
            <p>She decided to bring these nurturing elements into her skin and hair care products.</p>
            <p>A life-long follower and learner of Ayurveda, Mallika is passionate about spreading the richness of
              the ancient wisdom of Ayurveda for everyone's
              holistic health and well-being.</p>
            <p>
              A fervour to promote Ayurveda that can bring long-lasting health and well-ness inspired Mallika to
              create pure natural personal care products that are based on Ayurveda.{""} {""}<span className='text-PrimaryBrown font-PoppinsSemiBold'>... and thus was born Agharnya Organiko.</span></p>
              <p className='Heading24 text-center mt-5 max_sm:mt-3 font-normal'>Authentically Ayurvedic, Passion for Wellbeing, Crafted for Modern Day needs!</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Inspiration
