import React from 'react'
import MissionImg from "../../assets/images/AboutUs/Mission.svg"

const MissionSection = () => {
  return (
    <>
      <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
        <div className='F-JC-AI-CENTER justify-between max_768:flex-col'>
        <p className='commonHeading hidden max_768:block'>Mission :</p>
          <div className='w-[40%] flex justify-end max_768:w-[100%] max_768:justify-center max_768:mt-5'>
            <img src={MissionImg} alt='About_us' />
          </div>
          <div className='w-[56%] flex flex-col gap-5 max_768:w-[100%]'>
            <p className='commonHeading max_768:hidden'>Mission :</p>
            <div className='flex flex-col gap-[10px] max_768:mt-[20px] max_768:text-center'>
              <p className='NormalText16'>Provide high quality organic personal care health care products that promote health and beauty without compromising on safely or environmental impact.</p>
              <p className='NormalText16'>Educate consumers about the benefits & organic ingredients, transparency in labeling & the importance & making informed choices.</p>
              <p className='NormalText16'>Champion sustainable practices throughout oury supply chain from sourcing organic ingredients to eco-friendly packaging and responsible manufacturing.</p>
              <p className='NormalText16'>Empower individuals to take control & their personal care/healh care routine where minimizing their ecological footprint.</p>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default MissionSection
