import React from 'react'

const ServiceComponent = ({service,reverse}) => {
  return (
    <>
      <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] ">
        <div className={`flex gap-5 ${reverse ? 'flex-row-reverse' :''} max_md:flex-col-reverse`}>
          <div className='w-[50%] max_md:w-[100%]'>
            <div className='commonHeading uppercase'>
            {service.title}
            </div>
            <div className='NormalText16 mt-[20px]'>
            {service.text}
            </div>

            <div className='mt-10 w-full'>

            {service.details.map((product, index2) => {
              return (
                <React.Fragment key={index2}>
                  <div className='flex items-center gap-[15px] cursor-pointer max_sm:gap-[8px] mt-[14px]'>
                    <div className='self-start pt-[10px] max_esm:pt-[12px]'>
                    <div className='w-[10px] h-[10px] bg-PrimaryBrown rounded-full max_sm:w-[6px] max_sm:h-[6px] '></div>
                    </div>
                    <div className='NormalText18'>{product}</div>
                  </div>
                </React.Fragment>
              )
            })}
            </div>
          </div>
          <div className='w-[50%] max_md:w-[100%] max_md:flex max_md:justify-center'>
            <img src={service.image} alt='Service_image' />
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceComponent
