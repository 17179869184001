import React from 'react'
import GoalImg from "../../assets/images/AboutUs/Goals.svg"

const GoalSection = () => {
  return (
    <>
    <div className='bg-LightYellow'>
        <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
          <div className='F-JC-AI-CENTER justify-between max_768:flex-col-reverse'>
            <div className='w-[56%] flex flex-col gap-5 max_768:w-[100%]'>
              <p className='commonHeading max_768:hidden'>Goals :</p>
              <div className='flex flex-col gap-[10px] max_768:mt-[20px] max_768:text-center'>

              <p className='NormalText16'>Product excellence strive to consistently develop and offer the highest quality organic personal care/health care products, melting or exceeding industry standards.</p>
              <p className='NormalText16'>Customer satisfaction ensure that every customer experience is positive and that our products meet their need effectively.</p>
              <p className='NormalText16'><span className='font-PoppinsMedium'>Sustainability :</span> Reduce the environment impact of our products & operations through eco friendly practices.</p>
              <p className='NormalText16'><span className='font-PoppinsMedium'>Education :</span> Continuously educate both customers and employees about the benefits of organic personal care/health care and sustainable lining.</p>
              <p className='NormalText16'><span className='font-PoppinsMedium'>Innovation :</span> Faster a culture of innovation to create new, cutting-edge organic personal care/health care solutions.</p>
              <p className='NormalText16'><span className='font-PoppinsMedium'>Community engagement :</span> Engage with the support local communities particularly farmers and supplies of organic ingredients.</p>
              <p className='NormalText16'><span className='font-PoppinsMedium'>Advocacy :</span> Advocate for stricter industry regulation and standards that promote transparency and organic integrity.</p>
              </div>
            </div>
            <div className='w-[40%] flex justify-end max_768:w-[100%] max_768:justify-center max_768:mt-5'>
              <img src={GoalImg} alt='About_us' />
            </div>
            <p className='hidden commonHeading max_768:block'>Goals :</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default GoalSection
