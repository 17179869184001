import React from 'react'
import GoalImg from "../../assets/images/AboutUs/Values.svg"

const Valuesection = () => {
  return (
    <>
      <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
        <div className='F-JC-AI-CENTER justify-between max_768:flex-col'>
        <p className='hidden commonHeading max_768:block'>Values</p>
        <div className='w-[40%] flex justify-end max_768:w-[100%] max_768:justify-center max_768:mt-5'>
            <img src={GoalImg} alt='About_us' />
          </div>
          <div className='w-[56%] flex flex-col gap-5 max_768:w-[100%]'>
            <p className='commonHeading max_768:hidden'>Values</p>
            <div className='flex flex-col gap-[10px] max_768:mt-[20px]'>
              <p className='NormalText16'><span className='underline'>Quality</span> commit to providing the highest quality organic personal care/health care products.</p>
              <p className='NormalText16'><span className='underline'>Integrity</span> uphold transparency, honesty and ethical practices in all aspects of our business.</p>
              <p className='NormalText16'><span className='underline'>Sustainability</span> prioritize environmental responsibility and sustainable practices.</p>
              <p className='NormalText16'><span className='underline'>Customer-centric</span> put customers at the center of everything we do, striving for their satisfaction.</p>
              <p className='NormalText16'><span className='underline'>Innovation</span> Encourage creativity and innovation to drive progress in organic personal care/health care.</p>
              <p className='NormalText16'><span className='underline'>Community</span> Build strong, manually beneficial relationship with local communicate and suppliers.</p>
              <p className='NormalText16'><span className='underline'>Education</span> Promote knowledge and awareness about organic personal care/health care and environmental impact.</p>
            </div>

          </div>
          

        </div>
      </div>
    </>
  )
}

export default Valuesection
