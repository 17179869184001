import React from 'react'
import Layout from '../../Components/utils/Layout'
import BannerScreen from './BannerScreen'
import AboutUs from '../Home/AboutUs'
import ProductRange from '../Product/ProductRange'
import ContactUs from '../ContactUs/ContactUs'

const HomePage = () => {
  const handleScroll = (id) => {
    var element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
    <div className="relative w-[100%]">
      <Layout >


      <BannerScreen handleScroll={handleScroll} />

      <AboutUs />

      <ProductRange />

      <ContactUs />
      </Layout>
    </div>
    </>
  )
}

export default HomePage
