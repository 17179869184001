import { Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import HomePage from './Pages/Home/HomePage';
import ScrollToTop from './Components/utils/ScrollTop';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProcessPage from './Pages/Process/ProcessPage';
import AboutUsPage from './Pages/AboutUs/AboutUsPage';
import CategoryPage from './Pages/Category/CategoryPage';
import BabyPowder from './Pages/Product/BabyPowder';
import BabyDiaperBalm from './Pages/Product/BabyDiaperBalm';
import BabyMassageOil from './Pages/Product/BabyMassageOil';
import BabyBathingBar from './Pages/Product/BabyBathingBar';
import BabyShampooWash from './Pages/Product/BabyShampooWash';
import LittleSnooze from './Pages/Product/LittleSnooze';
import Services from './Pages/Services/Services';
import ContactUsPage from './Pages/ContactUs/ContactUsPage';


function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/process" element={<ProcessPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/category" element={<CategoryPage />} />
        <Route path="/product/baby-powder" element={<BabyPowder />} />
        <Route path="/product/baby-diaper-balm" element={<BabyDiaperBalm />} />
        <Route path="/product/baby-massage-oil" element={<BabyMassageOil />} />
        <Route path="/product/baby-bathing-bar" element={<BabyBathingBar />} />
        <Route path="/product/baby-shampoo-wash" element={<BabyShampooWash />} />
        <Route path="/product/little-snooze" element={<LittleSnooze />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
    </>
  );
}

export default App;
