import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import BabyPowderImg from "../../assets/images/Product/Baby powder img.svg"
import CommonProduct from '../../Components/CommonProduct'

const BabyPowder = () => {
  const [productDetails] = useState([{
    image:BabyPowderImg,
    name: 'Baby powder',
    title: ['Talc-free, Baby powder, for dry, Happy skin', 'Gentle, moisture absorbing dusting powder for babies & kids'],
    head1: 'Ingredient',
    head1Text: ['Arrowroot powder, cornstarch kaolin clay, Rose clay, Rose essential oil'],
    head2: 'Benefits',
    head2Text: ['Reduce chafing', 'Absorb excess moisture', 'Soothe irritation', 'Provide nourishment']
  }])
  return (
    <>
      <Layout >


      <CommonProduct productDetails={productDetails} image={BabyPowderImg} title="Baby powder"  />
      </Layout>
    </>
  )
}

export default BabyPowder
