import React from 'react'
import AboutImg from "../../assets/images/HomePage/AboutUs.svg"

const AboutUsSection = () => {
  return (
    <>
      <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
        <div className='F-JC-AI-CENTER justify-between max_768:flex-col-reverse max_768::items-center'>
          <div className='w-[40%] flex justify-end max_768:w-[100%] max_768:justify-center max_768:mt-5'>
            <img src={AboutImg} alt='About_us' />
          </div>
          <div className='w-[56%] flex flex-col gap-5 max_768:w-[100%]'>
            <p className='commonHeading max_768:hidden'>About us</p>
            <p className='NormalText16 max_768:mt-5 max_768:text-center'>Aghnya Organiko Health Care specializes in third-party manufacturing and private labeling for the healthcare and wellness industry. Our state-of-the-art facilities and experienced team ensure high-quality production and packaging. We offer customizable solutions for a wide range of products, helping businesses establish and grow their brands. Partner with us for excellence, transparency, and regulatory compliance in your product development journey.</p>
          </div>
          <p className='hidden max_768:block commonHeading w-[100%] text-center'>About us</p>

        </div>
      </div>
    </>
  )
}

export default AboutUsSection
