import React, { useState } from 'react'
import P1 from "../../assets/images/Process/image 1.svg"
import P2 from "../../assets/images/Process/image 2.svg"
import P3 from "../../assets/images/Process/image 3.svg"
import P4 from "../../assets/images/Process/image 4.svg"
import P5 from "../../assets/images/Process/image 5.svg"
import P6 from "../../assets/images/Process/image 6.svg"
import P7 from "../../assets/images/Process/image 7.svg"
import P8 from "../../assets/images/Process/image 8.svg"
import Image from "../../assets/images/Process/Image.svg"

const Process = () => {
  const [storeHoverIndex, setStoreHoverIndex] = useState()
  const [processList] = useState([
    {
      name: "01. Understanding your requirement", image: P1,
    },
    {
      name: "02. Choose product/product formula development", image: P2,
    },
    {
      name: "03. Packing selection", image: P3,
    },
    {
      name: "04. Cost estimation & Approvals", image: P4,
    },
    {
      name: "05. Labelling & Branding", image: P5,
    },
    {
      name: "06. Production", image: P6,
    },
    {
      name: "07. Final inspection", image: P7,
    },
    {
      name: "08. Dispatch", image: P8,
    },
  ])
  return (
    <>
      <div className="z-[40] mt-[70px] max_sm:mt-[60px] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px]">
        <div className='w-[100%] flex gap-[70px] max_md:flex-col max_md:gap-0'>

          <div className='relative w-[62%] flex flex-col items-center max_md:w-[100%]'>
            <p className='commonHeading uppercase'>Our Process</p>
            <p className='NormalText16 mt-5 text-center'>"From the Initial Concept to the Final Delivery: Meticulously Crafting Excellence at Every Step of the Journey."</p>
            <div className='relative flex items-center justify-center mt-[30px]'>
             
              <div class="loader relative">
              </div>
            <div className='absolute top-[12%] left-[13%] max_768:top-[15.5%] max_768:left-[16%] max_768:w-[68%] w-[90%]  max_md:w-auto '>
              <img src={Image} alt='Image' className='' />
            </div>
            </div>
          </div>


          <div className='w-[35%] flex flex-col gap-4 max_md:hidden max_sm:block max_sm:w-[100%] max_sm:gap-4'>
            {processList.map((process, index1) => {
              return (
                <React.Fragment key={index1}>
                  <div className=' flex items-center border-[1px] border-PrimaryWhite py-4 px-5 rounded-ButtonRadius gap-4 hover:shadow-[2px_2px_6px_0px_rgba(0,0,0,0.10)] hover:rounded-lg max_md:mt-5' onMouseEnter={() => setStoreHoverIndex(index1)}
                    onMouseLeave={() => setStoreHoverIndex()}
                  >
                    <div className='max_sm:hidden inner w-[22%] max-w-[54px]'>
                      <img className={`${index1 === storeHoverIndex ? 'scale-100' : 'scale-90'}`} src={process.image} alt='Process_img' />
                    </div>
                    <div className='hidden max_sm:block w-[22%] max-w-[54px]'>
                      <img src={process.image} alt='Process_img' />
                    </div>
                    <div className='NormalText16 w-[78%]'>
                      {process.name}
                    </div>
                  </div>
                </React.Fragment>
              )
            })}

          </div>

          {/* Tablate design */}
          <div className='hidden max_md:block max_sm:hidden max_md:mt-5'>

            <div className='flex gap-5'>
              <div className='w-[50%] flex flex-col gap-5'>

                {processList.map((process, index1) => {
                  return (
                    <React.Fragment key={index1}>
                      {index1 < 4 && (<>

                        <div className='flex items-center border-[1px] border-PrimaryWhite py-4 px-5
                        max_md:py-[10px] max_md:px-[14px] rounded-ButtonRadius gap-4 max_720:gap-2 hover:shadow-[2px_2px_6px_0px_rgba(0,0,0,0.10)] hover:rounded-lg max_720:min-h-[82px]'>
                          <div className='max_720:w-[18%]'>
                            <img src={process.image} alt='Process_img' />
                          </div>
                          <div className='NormalText16 w-[82%]'>
                            {process.name}
                          </div>
                        </div>
                      </>)}
                    </React.Fragment>
                  )
                })}
              </div>
              <div className='w-[50%] flex flex-col gap-5'>

                {processList.map((process, index1) => {
                  return (
                    <React.Fragment key={index1}>
                      {index1 > 3 && (<>

                        <div className='flex items-center border-[1px] border-PrimaryWhite py-4 px-5
                        max_md:py-[10px] max_md:px-[14px] rounded-ButtonRadius gap-4 max_720:gap-2 hover:shadow-[2px_2px_6px_0px_rgba(0,0,0,0.10)] hover:rounded-lg max_720:min-h-[82px]'
                        >
                          <div className='max_720:w-[18%]'>
                            <img src={process.image} alt='Process_img' />
                          </div>
                          <div className='NormalText16 w-[82%]'>
                            {process.name}
                          </div>
                        </div>
                      </>)}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Process
