import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import CommonProduct from '../../Components/CommonProduct'
import BathingBarImg from "../../assets/images/Product/Bath baby.svg"

const BabyBathingBar = () => {
  const [productDetails] = useState([
    {
      image:BathingBarImg,
      name: 'Baby bathing bar',
      title: ["Enriched with shea butter lavender & vanilla","Our baby saop extremely genete, sofa, nontoxic carefully crafted from calming lovender, hypoallergenic vanilla with your precious little one’s delicate skin in mind and is excellent for use on all ages of babies."],
      head1: 'Directions :',
      head1Text: ['Creats lather in your hands and massage gently on the skin to wash rinse well and pat dry.'],
      head2: 'Ingredients :',
      head2Text: ['Saponified organic coconut oil, oilive oil, castor oil, shea butter, cocoa butter, Avacado butter, vanila water, lovender essential oil, Aqua.']
    }
  ])
  return (
    <>
      <Layout >


      <CommonProduct productDetails={productDetails} image={BathingBarImg} title="Baby bathing bar" />
      </Layout>
    </>
  )
}

export default BabyBathingBar
