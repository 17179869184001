import React from 'react'
import VisionImg from "../../assets/images/AboutUs/Vision.svg"

const VisionSection = () => {
  return (
    <>
      <div className='bg-LightYellow'>
        <div className="z-[40] m-auto max-w-[1120px] max_xl:max-w-[980px] flex items-center justify-center max_md:px-[40px] max_sm:px-[20px] py-[100px] max_md:py-[70px] max_sm:py-[40px]">
          <div className='F-JC-AI-CENTER justify-between max_768:flex-col-reverse'>
            <div className='w-[56%] flex flex-col gap-5 max_768:w-[100%]'>
              <p className='commonHeading max_768:hidden'>Vision :</p>
              <div className='flex flex-col gap-[10px] max_768:text-center max_768:mt-[20px]'>

              <p className='NormalText16'>To create a world where individuals prioritize their well-being & the environment by embracing organic personal care/health care products that are soft effective & sustainable.</p>
              <p className='NormalText16'>This vision is brought to life by promoting the adoption of organic personal and healthcare products that seamlessly combine softness, effectiveness, and sustainability. Our mission is to foster a global shift, uniting personal health and ecological care in a brighter, sustainable future."</p>
              </div>
            </div>
            <div className='w-[40%] flex justify-end max_768:w-[100%] max_768:justify-center max_768:mt-5'>
              <img src={VisionImg} alt='About_us' />
            </div>
            <p className='hidden max_768:block commonHeading w-[100%] text-center'>Vision :</p>

          </div>
        </div>
      </div>
    </>
  )
}

export default VisionSection
