import React from 'react'
import ContactUsImg from "../../assets/images/ContactUs/1-Signatures-blog 1.svg"
import { ButtonFilled } from '../../Components/Button'

const ContactUs = () => {
  return (
    <>
    <div className="z-[40] relative flex items-center justify-center m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[16px] py-[70px]">
      <div className='p-10 flex justify-between shadow-[2px_2px_6px_0px_rgba(0,0,0,0.10)] max_768:flex-col max_768:gap-7 max_768:justify-center max_768:items-center max_768:p-6 max_sm:p-3'>
        <div className='w-[45%] flex flex-col gap-5 max_768:w-[90%] max_sm:w-[100%]'>
          <div className='commonHeading'>Contact us</div>
          <p className='NormalText16'>Our dedicated team is ready to assist you. Reach out to us today, and let's explore the endless possibilities of organic health solutions together. Your success is our priority."</p>
          <div>
            <img src={ContactUsImg} alt='ContactUsImg' />
          </div>
        </div>
          <div className='w-[52%] flex flex-col gap-5 max_768:w-[90%] max_sm:w-[100%]'>
            <div className='w-[100%] bg-PrimaryOrange rounded-ButtonRadius'>
              <input className='inputField' placeholder='Name' />
            </div>
            <div className='w-[100%] bg-PrimaryOrange rounded-ButtonRadius'>
              <input className='inputField' placeholder='Email' />
            </div>
            <div className='w-[100%] bg-PrimaryOrange rounded-ButtonRadius'>
              <input className='inputField' placeholder='Ph. No.' type='number' />
            </div>
            <div className='w-[100%] bg-PrimaryOrange rounded-ButtonRadius'>
            <textarea placeholder='Message' name='user_message' className='inputField resize-none h-[100px] max_md:h-[84px] ' />
            </div>
            <div className='flex justify-center'>
            <ButtonFilled title='Send' maxWidth="142px" />
            </div>
          </div>
      </div>
    </div>
    </>
  )
}

export default ContactUs
