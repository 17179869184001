import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import CommonProduct from '../../Components/CommonProduct'
import MassageOilImg from "../../assets/images/Product/MassageOil.svg"

const BabyMassageOil = () => {
  const [productDetails] = useState([
    {
      image:MassageOilImg,
      name: 'Calming baby massage oil',
      title: ["Brighten & soften coat with almond. Jojoba olive oil & coconut oil"],
      head1: 'Ingredient',
      head1Text: ['Sweet almong oil, organic coconut oil organic jojoba oil, organic sunflower oil, organic olive oil, vitamin E, lavender essential oil'],
      head2: 'How to use',
      head2Text: ['Rub on baby skin daily after bath and or before sleeping time perfect for a bonding baby massage.']
    }
  ])
  return (
    <>
      <Layout >


      <CommonProduct productDetails={productDetails} image={MassageOilImg} title="Calming baby massage oil" />
      </Layout>

    </>
  )
}

export default BabyMassageOil
