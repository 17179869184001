import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import ShampooImg from "../../assets/images/Product/Shampoo.svg"
import CommonProduct from '../../Components/CommonProduct'
const BabyShampooWash = () => {
  const [productDetails] = useState([
    {
      image: ShampooImg,
      name: 'Squeaky clean baby shampoo & wash',
      title: ["Lavender & aloe vera", "Gentle shampoo", "tear free sulfate and paraben free", "260 ml"],
      head1: 'Benefits :',
      head1Text: ['Provides a hydrating and calming effects aloe vera & coconut water so made up of water, which allows the skin to breath.', 'Heals wounds and fights infections.'],
      head2: 'Ingredients :',
      head2Text: ['Aloe vera extract, coconut water, lavender, vanilla, decyle glucoside, incagard PEG, capryloyl glycol, aqua q.s.'],
      head3: 'Description :',
      head3Text: ["wash your baby’s delicate skin and hair with our soothing wash and shampoo that gently cleanses and leaves skin soft and nourished.", "Gently enough for daily use and safe for even the most sensitive skin, the formula cleanser while organic botanicals like coconut, jojoba oil and aloe vera soothe, provides a calming aromatherapy benefits that sets your baby up for restful sleep."],

    }
  ])
  return (
    <>
      <Layout >


      <CommonProduct productDetails={productDetails} image={ShampooImg} title="Squeaky clean baby shampoo & wash" />
      </Layout>

    </>
  )
}

export default BabyShampooWash
