import React, {useState } from 'react';
import Logo from "../assets/images/Navbar/Logo (1).svg";

import { NavLink } from 'react-router-dom';
import CloseIcon from '../assets/images/Navbar/CloseIcon.png';

const MobileMenu = ({
  setIsMobileMenuOpen,
  nodeRef,
  outsideRef,
}) => {
  const [MenuList] = useState([
    {
      name: "Home",
      link: '/',
    },
    {
      name: "Category",
      link: '/category',
    },
    {
      name: "Process",
      link: '/process',
    },
    {
      name: "About us",
      link: '/about-us',
    },
    {
      name: "Services",
      link: '/services',
    },
    {
      name: "Contact us",
      link: '/contact-us',
    },
  ]);
 
  return (
    <>
      <div
        ref={nodeRef}
        className="z-[999999] fixed top-0 flex h-screen w-[100%] overflow-hidden"
      >
        <div ref={outsideRef} className="w-full bg-white shadow-lg ">
          <div className="F-JC-AI-CENTER h-[70px] justify-between px-[40px] max_sm:px-[20px] ">
            <div className="w-[90px] ">
              <img draggable="false" src={Logo} alt="Logo" />
            </div>
            <div className="flex items-center gap-3">
              
              <div
                className="cursor-pointer w-[30px]"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <img draggable="false" src={CloseIcon} alt="" />
              </div>
            </div>
          </div>

          <div className="w-full bg-white h-[calc(100vh-70px)] z-[100] overflow-y-scroll">
            {MenuList.map((menu, index) => (
              <React.Fragment key={index}>
                <NavLink
                  className={`flex w-full items-center border-[#00000033] no-underline`}
                  to={menu.link}
                >
                  {({ isActive }) => (
                    <div className="flex w-full flex-col">
                      <div
                        className="flex gap-2 items-center w-full py-4 px-[50px] max_sm:px-[30px] cursor-pointer NormalTextClass"
                      >
                        <div className={`cursor-pointer`}>{menu.name}</div>
                        
                      </div>
                    </div>
                  )}
                </NavLink>
              </React.Fragment>
            ))}
            
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
