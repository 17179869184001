import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const OurProductRangeComponent = () => {
  const [productList] = useState([
    { name: 'Baby powder', link: '/product/baby-powder' },
    { name: 'Baby Diaper Balm', link: '/product/baby-diaper-balm' },
    { name: 'Calming baby massage oil', link: '/product/baby-massage-oil' },
    { name: 'Baby bathing bar', link: '/product/baby-bathing-bar' },
    { name: 'Squeaky clean baby shampoo & wash', link: '/product/baby-shampoo-wash' },
    { name: 'Little snooze', link: '/product/little-snooze' },
  ])
  const navigate = useNavigate()
  return (
    <>
      <div className="z-[40] relative flex items-center justify-center m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] py-[70px] max_md:py-[50px] max_sm:py-[30px]">
        <div className='flex flex-col gap-5'>
          <div className='commonHeading uppercase text-center'>
            Our product range
          </div>
          <div className='NormalText16 text-center'>We have wide range of products, including baby care, soaps, personal hygiene, and derma range. Our top-notch facilities and experienced team ensure quality and compliance, making us the ideal partner for your brand's success.
          </div>
          <div className='flex flex-col gap-2 mt-2 w-full'>
            {productList.map((product, index1) => {
              return (
                <React.Fragment key={index1}>
                  <div className='flex items-center gap-[15px] cursor-pointer w-max max_sm:w-auto max_sm:gap-[8px]' onClick={()=>navigate(product.link)}>
                    <div className='max_sm:self-start max_sm:pt-[11px] max_esm:pt-[12px]'>
                    <div className='w-[10px] h-[10px] bg-PrimaryBrown rounded-full max_sm:w-[6px] max_sm:h-[6px] '></div>
                    </div>
                    <div className='commonHeadingPoppin'>{product.name}</div>
                  </div>
                </React.Fragment>
              )
            })}


          </div>
        </div>
      </div>
    </>
  )
}

export default OurProductRangeComponent
