import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import ServiceBannerSection from './ServiceBannerSection'
import ServiceComponent from './ServiceComponent'
import Service1Img from "../../assets/images/Services/[fpdl 2.svg"
import Service2Img from "../../assets/images/Services/[fpdl 3.svg"

const Services = () => {
  const [Service1] = useState({
    title:'Third party cosmetic manufacturer',
    text:'Aghnya Organiko Health Care holds the top position as a global brand in the production and distribution of natural essential oils and associated products. The company also specializes in private labeling and contract manufacturing for other brands. Their stringent quality standards, cutting-edge production facilities, and premium Essential Oils make them the preferred manufacturing partners for numerous renowned brands worldwide.',
    details:["Fully-automatic manufacturing systems","Over 2000+ fragrance options to choose from","We develop products as per the requirements.","More than 5000+ high quality natural ingredients.","Customized Innovative formulation development.","Well-researched cosmetic formulas that are lab-tested.","24/7 Electronic Surveillance At Out Manufacturing Facilities.","GMP+ISO+FDA-compliant facilities to ensure quality products","Achieve excellent formulations, with no compromise on quality.","A highly experienced and innovative product development team.","Inhouse product R&D team with more than 27+ years of experience."],
    image:Service1Img,
  })
  const [Service2] = useState({
    title:'Contract manufacturing',
    text:'Aghnya Organiko Health Care, a prominent figure in the cosmetic product manufacturing industry, offers you the chance to create personalized cosmetic product formulations for your brand. Our all-in-one process, which encompasses formulation, production, packaging, design, and shipping, is fully conducted in-house. With over 25 years of experience, we have collaborated with numerous clients and produced a multitude of cosmetic products.',
    details:["Highly qualified manufacturing chemists","Fully-automatic Manufacturing Vessels","ISO, Halal & GMP certified","Huge production capacity","In-house Steam Distillation & Cold-press Oil Machines"],
    image:Service2Img,
  })
  
  return (
    <>
    <Layout >


    <ServiceBannerSection />

    <div className='pt-[100px] max_md:pt-[50px] max_sm:pt-[30px]'>
    <ServiceComponent service={Service1} reverse={false}  />
    </div>
    <div className='py-[100px] max_md:py-[50px] max_sm:py-[30px]'>
    <ServiceComponent service={Service2} reverse={true}  />
    </div>
    </Layout>
    </>
  )
}

export default Services
