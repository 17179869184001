import React, { useState } from 'react'
import Layout from '../../Components/utils/Layout'
import CommonProduct from '../../Components/CommonProduct'
import LittleSnoozeImg from "../../assets/images/Product/Sleep.svg"

const LittleSnooze = () => {
  const [productDetails] = useState([
    {
      image: LittleSnoozeImg,
      name: 'Little snooze',
      title: ["lavender & aloe vera soothing lotion for baby"],
      head1: 'Benefits :',
      head1Text: ["Soften and soothe day stressed skin with this body lotion. Aloe vera is packed with this vitamins and beneficial content works to plump skin, lavender rejuvenates the senses and keeps skin healthy with it’s anti-inflammalory and antiseptic properteir."],
      head2: 'Ingredients :',
      head2Text: ["Veg glycerin, butyrospermum parkii ( Shea butter) simmondsia chinensis (jojoba) seed oil, Lavandula angustifolia (lavender), xanthan gum aloe barba dens is miller (aloe vera), ascaguard PEG, Aqua q.s."],
      head3: 'Description :',
      head3Text: ["Nourishing botanicals like organic aloe vera and jojoba oil hydrate deeply to keep the skin smooth and baby-soft. Scented with organic lavender essential oil. The formula offers relaxing aromatherapy to quit the mind, while you gently nourish your babies skin."],

    }
  ])
  return (
    <>
      <Layout >


      <CommonProduct productDetails={productDetails} image={LittleSnoozeImg} title="Little snooze" />
      </Layout>
      
    </>
  )
}

export default LittleSnooze
