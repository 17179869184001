export const ButtonFilled = ({ title = 'Button', onClick = () => { },background='',maxWidth }) => {
    return (
      <div
        onClick={onClick}
        style={{
            background: background ? background : '',
            maxWidth: maxWidth ? maxWidth : '',
          }}
        className={`z-[80] cursor-pointer rounded-ButtonRadius border-[1px] bg-PrimaryBrown py-2 max_sm:py-1 w-[160px] max_768:w-[130px] hover:bg-SecondaryBrown`}
      >
        <div className="F-JC-AI-CENTER font-PoppinsRegular text-white text-NormalText max:text-SmallSize">
          {title}
        </div>
      </div>
    );
  };