import React from 'react'
import Banner1 from "../../assets/images/HomePage/Banner1.svg"
import Banner2 from "../../assets/images/HomePage/Banner2.svg"
import Banner3 from "../../assets/images/HomePage/Banner3.svg"
import ScrollArrow from "../../assets/images/HomePage/Arrows_down.png"

const BannerScreen = ({handleScroll}) => {
  return (
    <div className='bg-LightYellow'>

      <div className="z-[40] mt-[70px] relative h-[calc(100vh-70px)] flex items-center justify-center m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[16px] max_sm:mt-[60px] max_sm:h-[calc(100vh-300px)]">
        <div className='flex flex-col justify-center items-center gap-10 max_md:gap-[30px] max_sm:gap-5'>
          <div className='flex flex-col items-center'>

            <div className='flex items-center gap-3 justify-center max_sm_400:gap-2'>
              <p className='LargeClass'>Natural</p>
              <div className='max_md:w-[26%] max_720:w-[24%] max_sm:w-[24%] max_esm:w-[22%]'>
                <img src={Banner1} alt='Product_image' />
              </div>
              <p className='LargeClass'>Product</p>
            </div>
            <div className='flex items-center gap-3 justify-center max_sm_400:gap-2'>
              <div className='max_md:w-[21%]  max_esm:w-[19%]'>
                <img src={Banner2} alt='Product_image' />
              </div>
              <p className='LargeClass'>For Best Result</p>
              <div className='max_md:w-[21%]  max_esm:w-[19%]'>
                <img src={Banner3} alt='Product_image' />
              </div>
            </div>
          </div>
          <p className='NormalText16 text-center w-[90%]'>Elevate your baby's care with our gentle, trusted solutions. Experience the luxury of our nourishing soaps. Prioritize personal hygiene with our innovative lineup. Unleash the beauty of your skin with our derma range.</p>
          <div >
            <p className='NormalText16 mt-12 max_md:mt-7 max_sm:mt-5'>Scroll down</p>
            <div className="scroll-down arrows " id='scrollDown' onClick={()=>{handleScroll("scrollDown")}}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default BannerScreen
