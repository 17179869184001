import React, { useState } from 'react'
import BabyCareImg from "../../assets/images/AboutUs/baby care.svg"
import SoapImg from "../../assets/images/AboutUs/Soaps.svg"
import PersonalImg from "../../assets/images/AboutUs/Personal hygiene.svg"
import DermaRangeImg from "../../assets/images/AboutUs/Derma range.svg"
import ProductCard from '../../Components/ProductCard'
import Slider from "react-slick";
import ArrowLeftImg from "../../assets/images/HomePage/VectorLeft.png"
import ArrowRightImg from "../../assets/images/HomePage/VectorRight.png"
import { useNavigate } from 'react-router-dom'

export const ArrowLeft = (props) => {
  const {  onClick } = props;
  return (<>
    <div className='absolute top-0 right-10 w-[30px] h-[30px] bg-white rounded-ButtonRadius F-JC-AI-CENTER' onClick={onClick}>
      <img src={ArrowLeftImg} alt='Arrow_left' />
    </div>
  </>)
}
export const ArrowRight = (props) => {
  const {  onClick } = props;
  return (<>
    <div className='absolute top-0 right-1 w-[30px] h-[30px] bg-white rounded-ButtonRadius F-JC-AI-CENTER' onClick={onClick}>
      <img src={ArrowRightImg} alt='Arrow_Right' />
    </div>
  </>)
}

const ProductRange = () => {
  const navigate = useNavigate()
  const [productRangeList] = useState([
    {
      image: BabyCareImg,
      productHeading: 'Baby care',
      productText: 'Quality products... good beginnings never end!'
    },
    {
      image: SoapImg,
      productHeading: 'Soaps',
      productText: 'Experience & burst of freshness!'
    },
    {
      image: PersonalImg,
      productHeading: 'Personal hygiene & other',
      productText: 'As prevention is better than care!'
    },
    {
      image: DermaRangeImg,
      productHeading: 'Derma range',
      productText: 'Recommended by Doctors!'
    },
  ])
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow:<ArrowRight  /> ,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <div className='bg-LightYellow'>

      <div className="z-[40] relative flex items-center justify-center m-auto max-w-[1120px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[16px] py-[70px]">
        <div className='flex flex-col items-center w-[100%]'>
          <p className='commonHeading uppercase'>Our product range</p>
          <p className='NormalText16 mt-5 text-center'>We have wide range of products, including baby care, soaps, personal hygiene, and derma range. Our top-notch facilities and experienced team ensure quality and compliance, making us the ideal partner for your brand's success.</p>
          <div className='flex gap-3 justify-between mt-10 max_md:hidden'>
            {productRangeList.map((list, index1) => {
              return (<React.Fragment key={index1 + 1}>
                <ProductCard image={list.image} productHeading={list.productHeading} productText={list.productText} />
              </React.Fragment>)
            })}

          </div>

          {/* SLIDER TABLATE */}
          <div className='hidden max_md:block w-[100%] mt-[10px]' style={{ textAlign: '-webkit-center' }}>

            <Slider {...settings}>
              {productRangeList.map((list, index1) => {
                return (<React.Fragment key={index1 + 1}>
                  <ProductCard image={list.image} productHeading={list.productHeading} productText={list.productText} />
                </React.Fragment>)
              })}

            </Slider>
          </div>


          <div className='F-JC-AI-CENTER w-[145px] max_768:w-[120px] rounded-ButtonRadius bg-white hover:bg-PrimaryBrown border-[1px] border-PrimaryBrown cursor-pointer mt-[30px] max_768:mt-[20px] text-PrimaryBrown hover:text-white'>
            <div className='py-3 max_768:py-2 font-PoppinsRegular text-NormalText max_md:text-TNormalText max_sm:text-MNormalText' onClick={()=> navigate("/category")}>View All</div>
          </div>



        </div>
      </div>
    </div>
  )
}

export default ProductRange
