import React from 'react'

const ProductCard = ({image,productHeading,productText}) => {
  return (
    <div className='bg-white rounded-ButtonRadius w-[24%] max-w-[265px] max_md:w-[100%] max_md:min-h-[330px] max_md:max-w-[230px] max_md_800:max-w-[200px] max_md_800:min-h-[300px] max_720:max-w-[220px] max_720:min-h-[315px]
    max_sm:min-h-[310px] max_esm_320:min-h-[290px]'>
    <div className='p-5 max_768:p-[12px] flex flex-col '>
      <div className='flex justify-center w-[100%]'>
        <img src={image} alt='Baby_Care' />
      </div>
      <p className='Heading24 mt-[20px] max_768:mt-[10px] leading-[24px] max_md:text-start'>{productHeading}</p>
      <p className='NormalText16 mt-[10px] max_md:text-start'>{productText}</p>
    </div>
  </div>
  )
}

export default ProductCard
