import React, { useState } from 'react';
import Logo from "../assets/images/Navbar/Logo (1).svg";
import Hamburger from "../assets/images/Navbar/Hamburger.png"

import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const Navbar = ({
  setIsMobileMenuOpen
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [MenuList] = useState([
    {
      name: "Home",
      link: '/',
    },
    {
      name: "Category",
      link: '/category',
    },
    {
      name: "Process",
      link: '/process',
    },
    {
      name: "About us",
      link: '/about-us',
    },
    {
      name: "Services",
      link: '/services',
    },
    {
      name: "Contact us",
      link: '/contact-us',
    },
  ]);
 
  return (
    <div className="z-[20] m-auto flex h-[70px] max-w-[1120px] items-center max_xl:max-w-[940px] max_md:px-[40px] max_sm:px-[20px] max_sm:h-[60px]">
      <div className="F-JC-AI-CENTER w-full justify-between ">
        <div
          onClick={() => {
            if (location.pathname === '/') {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            } else {
              navigate('/');
            }
          }}
          className="max_md:relative flex items-center justify-between cursor-pointer max_md:w-[100%] max_md:justify-center"
        >
          <div className="w-[140px] max_sm:w-[90px] max_md:hidden">
            <img draggable="false" src={Logo} alt="Logo" />
          </div>
          <div className="absolute left-0 hidden max_md:block">
            <div className="flex items-center gap-7 max_sm:gap-3">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setIsMobileMenuOpen(true);
                }}
              >
                <img src={Hamburger} alt="Menu" />
              </div>
            </div>
          </div>
          <div className="hidden w-[140px] max_sm:w-[90px] max_md:block">
            <img draggable="false" src={Logo} alt="Logo" />
          </div>
        </div>

        <div className="relative F-JC-AI-CENTER flex gap-x-[40px] max_xl:gap-x-[25px] max_md:hidden">
          {MenuList.map((menu, index) => (
            <div
              key={index}
            >
              <NavLink
                className="z-[90] flex h-[70px] max_sm:h-[60px] w-full items-center no-underline min-w-[80px] cursor-default"
                to={menu.link}
              >
                {({ isActive }) => (
                  <>
                    <div className='relative h-full flex justify-center items-center'>

                    <div
                      className={`cursor-pointer NormalTextClass `}
                    >
                      {menu.name}
                    </div>
                    <div className={`absolute bottom-0 w-full ${isActive ? 'border-b-4 border-PrimaryBrown' : ''} `}></div>
                    </div>
                    
                  </>
                )}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
