import React from 'react'
import BannerImg from "../../assets/images/Category/Banner.svg"

const CategoryBannerSection = () => {
  return (
    <>
      <div className="w-[100%] z-[40] mt-[70px] flex items-center justify-center max_sm:mt-[60px]">
       <img className='w-full' src={BannerImg} alt='' />
      </div>
    </>
  )
}

export default CategoryBannerSection
